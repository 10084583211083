import "./Login.css";
import AvtarLogo from "./appIcons/avtar.png";
import Arrow from "./images/Arrow.png";
import LeftArrow from "./images/left-arrow.png";
import Watermark from "./images/watermark.png";
import Logo from "./images/logo.png";
import config from "./appIcons/config.png";
import orders from "./appIcons/order.png";
import brand from "./appIcons/brand.png";
import stock from "./appIcons/stock.png";
import home from "./appIcons/home.png";
import carousel from "./appIcons/carousel.png";
import categories from "./appIcons/categories.png";
import logout from "./appIcons/logout.png";
import management from "./appIcons/management.png";
import reporting from "./appIcons/reporting.png";
import excelExport from "./images/excel_export.jpg";
const USER_REGEX = /^\[A-z\][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const API_URL = `https://www.kkmart.net.in/api`;
const IMAGE_URL = `https://www.kkmart.net.in/images`;
const INVOICE_URL = `https://www.kkmart.net.in/Images/invoice`;
const APP_VERSION = "2.0";
const API_KEY = "h%bDo?L6f~a8";
const ImageWidth = 600;
const ImageHeight = 600;
const BingMapAPIKey =
  "AnUpcJlx96WdDrHW3lzzwP37V7MkRa6ZamlgKFeVRieRYwwylCBh51fZpzBB2nAs";
const transitions = [
  "browse",
  "browse right",
  "drop",
  "fade",
  "fade up",
  "fade down",
  "fade left",
  "fade right",
  "fly up",
  "fly down",
  "fly left",
  "fly right",
  "horizontal flip",
  "vertical flip",
  "scale",
  "slide up",
  "slide down",
  "slide left",
  "slide right",
  "swing up",
  "swing down",
  "swing left",
  "swing right",
  "zoom",
];

export default {
  AvtarLogo,
  USER_REGEX,
  PWD_REGEX,
  API_URL,
  INVOICE_URL,
  Arrow,
  LeftArrow,
  Logo,
  APP_VERSION,
  API_KEY,
  IMAGE_URL,
  ImageWidth,
  ImageHeight,
  excelExport,
  transitions,
  Watermark,
  config,
  orders,
  brand,
  stock,
  home,
  management,
  carousel,
  categories,
  logout,
  reporting,
  BingMapAPIKey,
};
