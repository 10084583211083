import fetchService from "../service/dataService";
const getUserData = (prm) => {
  return fetchService.fetchService("/getAPUsers.php", prm);
};
const getCategory = (prm) => {
  return fetchService.fetchService("/getCategoryData.php", prm);
};
const getBrandData = (prm) => {
  return fetchService.fetchService("/getBrands.php", prm);
};
const getInvPropertyConfigData = (prm) => {
  return fetchService.fetchService("/getInvPropertyConfig.php", prm);
};
const getInvPropertyInJson = (prm) => {
  return fetchService.fetchService("/getInventoryProperties.php", prm);
};
const getInventory = (prm) => {
  return fetchService.fetchService("/getInventory.php", prm);
};
const getInventoryImages = (prm) => {
  return fetchService.fetchService("/getInventoryImages.php", prm);
};
const getInventoryOffers = (prm) => {
  return fetchService.fetchService("/getInventoryOffers.php", prm);
};
const getCaraousel = (prm) => {
  return fetchService.fetchService("/getCaraousel.php", prm);
};
const getSalesOffers = (prm) => {
  return fetchService.fetchService("/getSalesOffers.php", prm);
};
const getSalesOfferDetails = (prm) => {
  return fetchService.fetchService("/getSalesOfferDetails.php", prm);
};
const getSalesOffersDetail_ComboParent = (prm) => {
  return fetchService.fetchService(
    "/getSalesOffersDetail_ComboParent.php",
    prm
  );
};
const getSalesOffersDetail_ComboChildByParent = async (prm) => {
  return await fetchService.fetchService(
    "/getSalesOffersDetail_ComboChildByParent.php",
    prm
  );
};
//getSalesOffersDetail_ComboChildByParent.php
const getAppliancesType = (prm) => {
  return fetchService.fetchService("/getAppliancesType.php", prm);
};
const getConfigurations = (prm) => {
  return fetchService.fetchService("/getConfiguration.php", prm);
};
const getLPOffers = (prm) => {
  return fetchService.fetchService("/getLPOffer.php", prm);
};
const getOrders = (prm) => {
  return fetchService.fetchService("/getOrderInfo.php", prm);
};
const getAreas = async (prm) => {
  return await fetchService.fetchService("/getAreas.php", prm);
};
const getDistinctCityState = async (prm) => {
  return await fetchService.fetchService("/getDistinctCityState.php", prm);
};
const getOrderStatus = (prm) => {
  return fetchService.fetchService("/getOrderStatusList.php", prm);
};
const getActivityLog = (prm) => {
  return fetchService.fetchService("/getActivityLog.php", prm);
};
const getReportsManagerData = (prm) => {
  return fetchService.fetchService("/getReportsManagerData.php", prm);
};
const saveUser = (prm) => {
  return fetchService.fetchService("/manageUserData.php", prm);
};
const saveCategory = (prm) => {
  return fetchService.fetchService("/manageCategory.php", prm);
};
const saveLPOfferData = (prm) => {
  return fetchService.fetchService("/saveLPOfferData.php", prm);
};
const saveInvPropConfiguration = (prm) => {
  return fetchService.fetchService("/manageInvPropertyConfig.php", prm);
};
const saveBranch = (prm) => {
  return fetchService.fetchService("/manageBrands.php", prm);
};
const saveInventory = (prm) => {
  return fetchService.fetchService("/saveInventory.php", prm);
};
const saveInventoryProperties = (prm) => {
  return fetchService.fetchService("/saveInventoryProperties.php", prm);
};
const saveInventoryOffers = (prm) => {
  return fetchService.fetchService("/saveInventoryOffers.php", prm);
};
const saveLinkOfferWithInventory = (prm) => {
  return fetchService.fetchService("/saveLinkOfferWithInventory.php", prm);
};
const importInventory = (prm) => {
  return fetchService.fetchService("/importInventory.php", prm);
};
const saveInventoryImages = (prm) => {
  return fetchService.fetchService("/saveInventoryImages.php", prm);
};
const saveSalesOffer = (prm) => {
  return fetchService.fetchService("/saveSalesOffers.php", prm);
};
const saveAreaData = async (prm) => {
  return await fetchService.fetchService("/saveAreaData.php", prm);
};
//saveAreaData.php
const deleteUser = (prm) => {
  return fetchService.fetchService("/deleteUser.php", prm);
};
const deleteCategory = (prm) => {
  return fetchService.fetchService("/deleteCategory.php", prm);
};
const deleteBranch = (prm) => {
  return fetchService.fetchService("/deleteBrands.php", prm);
};
const deleteInventory = (prm) => {
  return fetchService.fetchService("/deleteInventory.php", prm);
};
const deleteInventoryImage = async (prm) => {
  return await fetchService.fetchService("/deleteInventoryPhoto.php", prm);
};
const deletePropertyConfig = (prm) => {
  return fetchService.fetchService("/deleteInvPropertyConfiguration.php", prm);
};
const deleteCaraousel = (prm) => {
  return fetchService.fetchService("/deleteCaraousel.php", prm);
};
const deleteOffer = (prm) => {
  return fetchService.fetchService("/deleteOffer.php", prm);
};
const deleteLPOffer = (prm) => {
  return fetchService.fetchService("/deleteLPOffer.php", prm);
};
const updateOrderStatus = (prm) => {
  return fetchService.fetchService("/updateOrderStatus.php", prm);
};
const login = (prm) => {
  return fetchService.fetchService("/login.php", prm);
};
const setDataInLocalStorage = (key, data) => {
  localStorage.setItem(key, data);
};
const getDataFromLocalStorage = (key) => {
  const localData = localStorage.getItem(key);
  return localData;
};
const getLocationCoords = (prm) => {
  return fetchService.getLocationCoords(prm);
};
const getCompanyInfo = async (prm) => {
  return await fetchService.fetchService("/getCompanyInfo.php", prm);
};
const deleteDataFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};
const setAreaDeliverable = async (prm) => {
  return await fetchService.fetchService("/setAreaDeliverable.php", prm);
};
const getReportData = async (prm) => {
  return await fetchService.fetchService("/getReportData.php", prm);
};
const getItemDetailByItemId = async (prm) => {
  return await fetchService.fetchService("/getItemDetailByItemId.php", prm);
};
//getReportData.php
const uploadFile = (
  selectedFile,
  category,
  type,
  productId,
  userId,
  salesOfferId
) => {
  return fetchService.uploadFile(
    selectedFile,
    category,
    "/uploadFile.php",
    type,
    productId,
    userId,
    salesOfferId
  );
};

export default {
  getUserData,
  getCategory,
  getBrandData,
  getInvPropertyConfigData,
  getInvPropertyInJson,
  getInventoryImages,
  getInventory,
  getSalesOfferDetails,
  getSalesOffersDetail_ComboParent,
  getSalesOffersDetail_ComboChildByParent,
  getInventoryOffers,
  getCaraousel,
  getSalesOffers,
  getAppliancesType,
  getLPOffers,
  getOrders,
  getOrderStatus,
  getConfigurations,
  getActivityLog,
  getAreas,
  getDistinctCityState,
  getLocationCoords,
  getReportsManagerData,
  getReportData,
  getItemDetailByItemId,
  saveInventoryProperties,
  saveInventoryOffers,
  saveInventoryImages,
  saveSalesOffer,
  saveLinkOfferWithInventory,
  saveUser,
  saveBranch,
  saveCategory,
  saveInvPropConfiguration,
  saveInventory,
  saveLPOfferData,
  setAreaDeliverable,
  saveAreaData,
  deleteUser,
  deleteCategory,
  deleteBranch,
  deletePropertyConfig,
  deleteInventory,
  deleteInventoryImage,
  deleteCaraousel,
  deleteOffer,
  deleteLPOffer,
  login,
  setDataInLocalStorage,
  getDataFromLocalStorage,
  deleteDataFromLocalStorage,
  uploadFile,
  importInventory,
  updateOrderStatus,
  getCompanyInfo,
};
