import React, { useEffect, useState, useRef, useContext } from "react";
import appAssets from "../assets/assets";
import LoginAuth from "../Auth/LoginAuth";
import { json, useNavigate } from "react-router-dom";
import LoginContext from "../context/LoginContext";
import data from "../commonfunction/data";

const Login = (props) => {
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [loginError, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const navigation = useNavigate();
  const auth = useContext(LoginContext);

  useEffect(() => {
    //console.log(props.showHideSideBar(false));
  }, [user, pwd]);
  useEffect(() => {
    props.showHideSideBar(false);
  }, [loginError]);

  const onSubmit = () => {
    const userCredentials = { userName: user, password: pwd };
    LoginAuth.handleSubmit(userCredentials).then((cred) => {
      if (cred.length === 0) {
        setErrMsg("Invalid Credentials....");
        props.showHideSideBar(false);
        return;
      }
      let state = {
        id: cred[0].id,
        userName: cred[0].email,
        password: cred[0].password,
        isAuthonticated: true,
        role: cred[0].role,
        firstName: cred[0].firstName,
        lastName: cred[0].lastName,
      };
      console.log("state", state);
      auth.updateAuth(state);
      data.setDataInLocalStorage("loginDetails", JSON.stringify(state));
      props.showHideSideBar(true);
      navigation("/Home");
    });
  };
  return (
    <div className="container">
      <div
        className="shadow-lg p-3 mb-5 bg-white rounded"
        id="loginContainer"
        // style={{
        //   background: "white",
        //   height: "35%",
        //   width: "35%",
        //   marginTop: "5%",
        //   marginLeft: "34%",
        // }}
      >
        <div className="mainContainer">
          <div class="imgcontainer">
            <img src={appAssets.AvtarLogo} alt="Avatar" class="avatar" />
          </div>
          <label for="uname" className="form-label">
            <b>Username</b>
          </label>
          <input
            type="text"
            placeholder="Enter Email or Mobile No"
            name="uname"
            required
            onChange={(e) => setUser(e.target.value)}
            className="form-control"
          />
          <label for="psw" className="form-label">
            <b>Password</b>
          </label>
          <input
            type="password"
            placeholder="Enter Password"
            name="psw"
            required
            onChange={(e) => setPwd(e.target.value)}
            className="form-control"
          />
          <button
            onClick={() => {
              onSubmit();
            }}
            className="btn btn-success"
          >
            Login
          </button>
          <div class="container">
            <label
              className="form-check-label"
              style={{ width: 150, marginTop: -20 }}
            >
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  name="remember"
                  style={{ marginTop: 18 }}
                />
              </div>
              Remember me
            </label>
          </div>
          <br />
          <span style={{ color: "red" }}>{loginError}</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
