import assets from "../assets/assets";
const fetchService = async (url, prm) => {
  var myHeaders = {
    Accept: "application/x-www-form-urlencoded",
    "Content-Type": "application/x-www-form-urlencoded",
  };

  var bodyData = {
    app_version: assets.APP_VERSION,
    security_key: assets.API_KEY,
  };

  bodyData = { ...bodyData, ...prm };
  // console.log("bodyData", bodyData);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(bodyData),
    redirect: "follow",
  };
  const uri = `${assets.API_URL}${url}`;
  return await fetch(uri, requestOptions)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error("fetch_error", error);
    });
};
const uploadFile = async (
  selectedFile,
  category,
  url,
  type,
  productId,
  userId,
  salesOfferId
) => {
  const formData = new FormData();

  formData.append("file", selectedFile);
  formData.append("category", category);
  formData.append("type", type);
  formData.append("productId", productId);
  formData.append("app_version", assets.APP_VERSION);
  formData.append("security_key", assets.API_KEY);
  formData.append("userId", userId);
  formData.append("salesOfferId", salesOfferId);
  console.log("formData", selectedFile, type, salesOfferId);
  const uri = `${assets.API_URL}${url}`;
  return await fetch(uri, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error("fetch_error", error);
    });
};
const getLocationCoords = async (prm) => {
  //var myHeaders = new Headers();
  var myHeaders = {
    key: assets.BingMapAPIKey,
    "Access-Control-Allow-Headers": "*",
    "Content-type": "application/json",
  };

  //myHeaders.append("", assets.BingMapAPIKey);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const adminDistrict = prm.adminDistrict;
  const locality = prm.locality;
  const addressLine = prm.addressLine;
  const userLocation = prm.userLocation;
  const maxResults = prm.maxResults;
  const userIp = prm.userIp;
  const usermapView = prm.usermapView;
  const includeNeighborhood = prm.includeNeighborhood;
  const postalCode = prm.postalCode;
  const url = `https://dev.virtualearth.net/REST/v1/Locations?countryRegion=IN&adminDistrict=${adminDistrict}&locality=${locality}&postalCode=${postalCode}&addressLine=${addressLine}&userLocation=${userLocation}&userIp=${userIp}&usermapView=${usermapView}&includeNeighborhood=${includeNeighborhood}&maxResults=${maxResults}&key=${assets.BingMapAPIKey}`;
  console.log("url", url);
  return await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((JSONResult) => {
      return JSONResult;
    })
    .catch((error) => console.log("error", error));
};
export default { fetchService, uploadFile, getLocationCoords };
