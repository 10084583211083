import assets from "../assets/assets";
import data from "../commonfunction/data";

const handleSubmit = async (prm) => {
  try {
    return data.login(prm);
  } catch (err) {}
};

export default { handleSubmit };
