import React, { useContext, useEffect } from "react";
import LoginContext from "../context/LoginContext";
import { useNavigate } from "react-router-dom";
import data from "../commonfunction/data";
import assets from "../assets/assets";
import "../assets/css/Watermark.css";

const Home = (props) => {
  const loginAuth = useContext(LoginContext);
  const navigation = useNavigate();
  useEffect(() => {
    props.showTitle("Home");
    props.hideSideBar(false);
    const loginData = JSON.parse(data.getDataFromLocalStorage("loginDetails"));
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
  }, []);

  return (
    <>
      <div class="image">
        <img src={assets.Logo} />
      </div>
    </>
  );
};

export default Home;
