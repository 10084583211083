import React from "react";

const Loader = (props) => {
  const showLoader = props.showLoader;
  return (
    <>
      <div
        class="modal appLoader"
        tabindex="-1"
        style={
          showLoader === true
            ? {
                display: "block",
              }
            : { display: "none" }
        }
      >
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal-content"
            style={{ backgroundColor: "#ffffff4d", border: "none" }}
          >
            <div class="modal-body">
              <div class="spinner-grow text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-danger" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
